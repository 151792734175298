import axios from "axios";
import Web3 from "web3";

export const APIURL = process.env.REACT_APP_API_URL;

export const toastTime = 5000;

export const postConfig = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
};

export const formDataConfig = {
  headers: { "Content-Type": "application/form-data" },
};

export const multiPartConfig = {
  headers: { "Content-Type": "multipart/form-data" },
  cache: false,
};

export const axiosInstance = axios.create({
  baseURL: APIURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const convertFormData = (object) => {
  const data = new FormData();
  Object.entries(object).forEach(([key, value]) => data.set(key, value));
  return data;
};

export const ethToXdcAddress = (address) => address.replace(/^0x/, "xdc");
export const xdcToEthAddress = (address) => address.replace(/^xdc/, "0x");

export const toChecksumAddress = (address) => {
  return Web3.utils.toChecksumAddress(address);
};

export const numberToReadable = (labelValue) => {
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? parseFloat(Math.abs(labelValue) / 1.0e9, 2).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? parseFloat(Math.abs(labelValue) / 1.0e6, 2).toFixed(2) + "M"
    : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)).toLocaleString("en-US");
};
